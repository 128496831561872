<template>
  <k-field-group language-prefix="instruction">
    <v-container>
      <v-form ref="form" v-model="valid" class="mt-5">
        <v-row justify="center" style="min-width: 100%;">
          <v-col cols="12" lg="10">
            <v-subheader class="pa-0">{{ $tc('instruction.title', 1) }}</v-subheader>
            <v-card>
              <v-card-text>
                <ClientAutocomplete field="fields.clientName" v-model="values.clientId" :return-object="false"/>
                <KCheckbox v-model="values.isActive" field="fields.isActive"/>
                <KCheckbox v-model="values.isBillable" field="fields.isBillable"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" lg="5">
            <v-subheader class="pa-0">{{ $t('instruction.actions.title') }}</v-subheader>
            <v-card>
              <v-card-text>

                <KTextarea field="actions.fields.comment"
                           v-model="values.actions.fields.comment"
                           counter
                           :rows="3"
                           :validateOnBlur="false"
                           :rules="commentRules"/>
                <TemplateAutocomplete field="actions.document.templateId"
                                      v-model="values.actions.document.templateId"
                                      clearable
                                      :return-object="false"/>
                <KTextField field="actions.document.sourceFolder" v-model="values.actions.document.sourceFolder"/>
                <KTextField field="actions.document.sourceFileName" v-model="values.actions.document.sourceFileName"/>
                <KTextField field="actions.document.folder" v-model="values.actions.document.folder"/>
                <KTextField field="actions.document.fileName" v-model="values.actions.document.fileName"/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="5">
            <v-subheader class="pa-0">{{ $t('instruction.conditions.deliveryAddress.title') }}</v-subheader>
            <v-card>
              <v-card-text>
                <KTextField field="conditions.deliveryAddress.companyName"
                            v-model="values.conditions.deliveryAddress.companyName"/>
                <KTextField field="conditions.deliveryAddress.department"
                            v-model="values.conditions.deliveryAddress.department"/>
                <KTextField field="conditions.deliveryAddress.firstName"
                            v-model="values.conditions.deliveryAddress.firstName"/>
                <KTextField field="conditions.deliveryAddress.lastName"
                            v-model="values.conditions.deliveryAddress.lastName"/>
                <KTextField field="conditions.deliveryAddress.street"
                            v-model="values.conditions.deliveryAddress.street"/>
                <KTextField field="conditions.deliveryAddress.houseNumber"
                            v-model="values.conditions.deliveryAddress.houseNumber"/>
                <KTextField field="conditions.deliveryAddress.houseNumberAddition"
                            v-model="values.conditions.deliveryAddress.houseNumberAddition"/>
                <KTextField field="conditions.deliveryAddress.postalCode"
                            v-model="values.conditions.deliveryAddress.postalCode"/>
                <KTextField field="conditions.deliveryAddress.city" v-model="values.conditions.deliveryAddress.city"/>
                <KTextField field="conditions.deliveryAddress.country"
                            v-model="values.conditions.deliveryAddress.country"/>
                <KTextField field="conditions.deliveryAddress.email" v-model="values.conditions.deliveryAddress.email"/>
                <KTextField field="conditions.webshopSourceId" v-model="values.conditions.webshopSourceId"/>
                <KTextField field="conditions.templateId" v-model="values.conditions.templateId"/>
                <KCheckbox
                    field="conditions.hasComplimentCardText"
                    v-model="values.conditions.hasComplimentCardText"/>
                <KTextField field="conditions.source" v-model="values.conditions.source"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </k-field-group>
</template>

<script lang="js">
import Instruction from '@/application/models/instruction.js';
import ClientAutocomplete from '@/components/autocompletes/Client.vue';
import TemplateAutocomplete from '@/components/autocompletes/Template.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import Form from '@/components/VuetifyResourceBaseForm.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';

export default {
  name: 'InstructionForm',
  extends: Form,
  components: {
    KTextarea,
    KTextField,
    KCheckbox,
    ClientAutocomplete,
    TemplateAutocomplete,
    KFieldGroup,
  },
  data: () => ({
    values: new Instruction(),
  }),
  computed: {
    commentRules() {
      return [v => typeof (v) === 'undefined' || v.length <= 200 || this.$t('validation.maxCharacters', { maxChar: 200 })];
    },
    complimentCardTextRules() {
      return [v => typeof (v) === 'undefined' || v.length <= 350 || this.$t('validation.maxCharacters', { maxChar: 350 })];
    },
  },
};
</script>
